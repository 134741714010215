import React from "react";
import AuthMicrositeBody from "../components/authMicrositeBody/AuthMicrositeBody";

const AuthMicrositePage = () => {
  return (
    <>
      <AuthMicrositeBody />
    </>
  );
};

export default AuthMicrositePage;
