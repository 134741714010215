import React from 'react'
import NewUserRegistrationEmailCheckBody from '../../components/newUserRegistrationBody/NewUserRegistrationEmailCheckBody';
const NewUserEmailCheckPage = () =>{


    return (
      <> 
        <NewUserRegistrationEmailCheckBody/>
      </> 
    )
 

}
export default NewUserEmailCheckPage