import React from 'react'
import MainLoader from '../components/Loader/MainLoader'

const NotFoundPage = () =>{
  
  
    return (
      <> 
        <MainLoader/>
     </> 
    )
}
export default NotFoundPage