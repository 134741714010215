import React, { Fragment } from "react";
import JournalSaveItemList from "./JournalSaveItemList";

const JournalBody = () => {
  return (
    <Fragment>
      <JournalSaveItemList />
    </Fragment>
  );
};

export default JournalBody;
